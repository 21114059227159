import { IFundGrant, ISponsorGrant } from 'api/redux/services/userApi';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { Auth0AppMetadata } from './types';
import { ROLES } from '../Roles';

export const isSponsorAdmin = (sponsorId: number): boolean => {
	const { grants } = useSelector(selectDropdown);
	const { availableSponsors: userGrants } = grants;

	return (
		userGrants.find(
			(g) => g.id === sponsorId && g.role === ROLES.SPONSOR_ADMIN,
		) !== undefined
	);
};

export const isSponsorUser = (sponsorId: number): boolean => {
	const { grants } = useSelector(selectDropdown);
	const { availableSponsors: userGrants } = grants;

	return (
		userGrants.find(
			(g) => g.id === sponsorId && g.role === ROLES.SPONSOR_USER,
		) !== undefined
	);
};

export const isInvestorAdmin = (
	sponsorId: number,
	fundId: number,
	InvestorId: number,
): boolean => {
	const { grants } = useSelector(selectDropdown);
	const { availableSponsors: userGrants } = grants;

	const sponsorGrant: ISponsorGrant | undefined = userGrants.find(
		(g) => g.id === sponsorId,
	);

	if (!sponsorGrant) return false;

	const fundGrant: IFundGrant | undefined = sponsorGrant.funds.find(
		(f) => f.id === fundId,
	);

	if (!fundGrant) return false;

	return (
		fundGrant.investors.find(
			(i) => i.id === InvestorId && i.role === ROLES.INVESTOR_ADMIN,
		) !== undefined
	);
};

export const isInvestorUser = (
	sponsorId: number,
	fundId: number,
	InvestorId: number,
): boolean => {
	const { grants } = useSelector(selectDropdown);
	const { availableSponsors: userGrants } = grants;

	const sponsorGrant: ISponsorGrant | undefined = userGrants.find(
		(g) => g.id === sponsorId,
	);

	if (!sponsorGrant) return false;

	const fundGrant: IFundGrant | undefined = sponsorGrant.funds.find(
		(f) => f.id === fundId,
	);

	if (!fundGrant) return false;

	return (
		fundGrant.investors.find(
			(i) => i.id === InvestorId && i.role === ROLES.INVESTOR_USER,
		) !== undefined
	);
};

export const getUserRoles = (appMetadata: Auth0AppMetadata): string[] => {
	const { grants } = useSelector(selectDropdown);
	const { currentSponsor, currentFund, currentInvestor } = grants;
	const roles: string[] = [];

	if (appMetadata.roles.includes(ROLES.SUPER_ADMIN))
		roles.push(ROLES.SUPER_ADMIN);
	if (isSponsorAdmin(currentSponsor.id)) roles.push(ROLES.SPONSOR_ADMIN);
	if (isSponsorUser(currentSponsor.id)) roles.push(ROLES.SPONSOR_USER);
	if (isInvestorAdmin(currentSponsor.id, currentFund.id, currentInvestor.id)) {
		roles.push(ROLES.INVESTOR_ADMIN);
	}
	if (isInvestorUser(currentSponsor.id, currentFund.id, currentInvestor.id)) {
		roles.push(ROLES.INVESTOR_USER);
	}

	return roles;
};
